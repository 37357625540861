<template>
  <div class="delimiter-title" :style="sliderStyle">
    <div class="container">
      <div class="delimiter-title__wrapper">
        <div class="delimiter-title__line" :class="{ 'delimiter-title__line_disabled' : !line }"></div>
        <div class="delimiter-title__item font font_title-xl font_bold font_uppercase">

          <div
            v-if="title || isImage"
            class="delimiter-title__body"
            :class="{ 'delimiter-title__body_image' : isImage }"
          >
            <DynamicImage
              v-if="isImage"
              :image="objectImage"
              :image-src="pathImage"
              :ratio="ratio"
              :sizes="sizes"
              :ext="imageExt"
            />
            <template v-else>
              {{ title }}
            </template>
          </div>
        </div>
        <div class="delimiter-title__line" :class="{ 'delimiter-title__line_disabled' : !line }"></div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicImage from '~/components/elements/DynamicImage';
export default {
  name: 'Delimiter',
  components: {DynamicImage},
  props: {
    title: {
      type: String,
      default: '',
    },
    image: {
      type: [Object, String],
      default() {
        return ''
      },
    },
    isImage: {
      type: Boolean,
      default: false,
    },
    line: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: '#fff',
    },
    lineColor: {
      type: String,
      default: '#333',
    },
    background: {
      type: String,
      default: '#000',
    },
    ratio: {
      type: Object,
      default() {
        return {}
      },
    },
    sizes: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    sliderStyle() {
      let styles = {}

      if (this.isImage) {
        styles = {
          '--image-ratio-pc': this.ratio.pc,
          '--image-ratio-tablet': this.ratio.tablet,
          '--image-ratio-mobile': this.ratio.mobile,
          '--image-width-pc': `${this.sizes.pc.width}px`,
          '--image-width-tablet': `${this.sizes.tablet.width}px`,
          '--image-width-mobile': `${this.sizes.mobile.width}px`,
        }
      }

      return {
        '--delimiter-background': this.background,
        '--line-color': this.lineColor,
        '--text-color': this.color,
        ...styles,
      }
    },
    imageExt() {
      return this.image?.sourceExt || ''
    },
    pathImage() {
      return typeof this.image === 'string' ? this.image : ''
    },
    objectImage() {
      return typeof this.image === 'string' ? {} : this.image
    },
  },
}
</script>
